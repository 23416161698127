import React, { Component } from "react";
import Footer from '../layout/Footer';

import NavigationBarMobile from '../layout/NavigationBarMobile';
import NavigationBarDesktop from '../layout/NavigationBarDesktop';

import Helmet from 'react-helmet';
import withSizes from 'react-sizes';

import JakePic from "../../images/JakeRoof1.jpg" ;

class LessonsPage extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);

        this.state = {
            navbarIsOpen: false
        }
    }

    toggle() {
        this.setState({
            navbarIsOpen: !this.state.navbarIsOpen
        })
    }

    render() {

        const paragraphMargin = this.props.collapseButtonIsShowing ? "0px" : "20px"

        return (
            <div>
                <Helmet>
                    <title>{"Jake Whitesell – Saxophone & Clarinet Lessons in Burlington & Montpelier, VT"}</title>
                    <body className={"normal"} />
                </Helmet>
                {window.innerWidth < 1050 ?
                    <NavigationBarMobile />
                    :
                    <NavigationBarDesktop />
                }

                <div className="row">
                    <div className="col-lg-12">
                        <p className="customHeader" style={{ textAlign: 'center' }}>
                            Lessons: Saxophone, Clarinet, Jazz Improvisation
                            {/* <br/>  <img className="img-fluid" style={{ width : "400px"}} src={JakePic} /> */}

                        </p>
                    </div>
                </div>

                <div style={{ height: "25px" }}></div>

                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8" >

                        {this.props.width < 1150 ?
                            <img className="img-fluid" style={{ width: "100%", marginBottom: "16px" }} src={JakePic} />
                            :
                            <img className="img-fluid float-left" style={{ width: "25vw", marginRight: "30px", marginLeft: paragraphMargin, marginBottom: "16px" }} src={JakePic} />
                        }

                        {this.props.width < 1150 ? <div style={{ height: "16px" }}></div>  : <b></b> }

                        <p className="customText" style={{ textAlign: "justify", marginLeft: paragraphMargin, marginRight: paragraphMargin }}>
                        Jake teaches private saxophone lessons in Montpelier and Burlington, Vermont to students of all ages and abilities (absolute beginner to advanced). Jake also teaches clarinet lessons for beginner to intermediate students. {/*and well as lessons in jazz and improvisation, music theory, ear training, and reading and writing music notation.*/} 
                        {/* Online lessons are available via Facetime, Zoom, or Skype. 
                        
                        Jake teaches private saxophone lessons in Montpelier and Burlington, Vermont to students of all ages and abilities (beginner to advanced). Online lessons are available via Facetime, Zoom, or Skype. Jake also offers lessons in jazz and improvisation, music theory, ear training, and reading and writing music notation. 
                        
                        Jake teaches private saxophone and clarinet lessons in Montpelier and Burlington, Vermont, to students of all ages. Online lessons are also available via Facetime, Zoom, or Skype. Jake teaches saxophone students of all abilities (beginner, intermediate, and advanced). Clarinet lessons are available for beginning and intermediate players. Jake also offers lessons in jazz and improvisation, music theory, ear training, and reading and writing music notation.
                        
                        Jake is currently accepting private students in the Montpelier and Burlington VT areas, as well as online via FaceTime, Zoom or Skype. Jake offers saxophone lessons for students of all ages and abilities (beginner to advanced.) Jake also offers lessons to intermediate and advanced players of any instrument in music theory, learning music by ear, reading and writing music notation, playing jazz and improvising. */}
                        <br/><br/>
                            Lessons are tailored to the specific needs and interests of each student. Jake is committed to patiently guiding students along their musical journey, providing mentorship, inspiration, encouragement, and expertise, in a fun and engaging learning environment. 
                        <br/><br/>    
                            Jake specializes in teaching jazz, improvisation, and playing by ear, in addition to traditional instruction in instrumental technique, music reading, and music theory.
                        <br/><br/>
                            Jake draws from 25 years of <a href="./about">extensive experience as a professional musician</a> performing and teaching in a variety of genres including classical, jazz, Latin jazz, blues, and various popular styles.  {/* Lessons with Jake cover many aspects of musicianship, including technique, tone quality, rhythm, musicality and expression, reading music, playing by ear, ear training, music theory, improvisation, and cultivating helpful mindsets and practice techniques. */}
                        <br/><br/>
                            Jake studied with world-renowned saxophonist and educator <a href="http://www.timothymcallister.com" target="_blank">Dr. Timothy McAllister</a> at The Crane School of Music at S.U.N.Y. Potsdam, earning a B.M. in Saxophone Performance with a minor in Jazz Studies in 2005. During highschool, Jake studied clarinet with <a href="http://www.belcantoreeds.com/about_us.html" target="_blank">Frank Wangler</a>, clarinet Professor at The Crane School of Music at S.U.N.Y. Potsdam.
                        <br/><br/>
                            Jake is on the teaching faculty at <a href="https://www.monteverdimusic.org/" target="_blank">Monteverdi Music School</a> in Monteplier, VT and <a href="https://www.vyo.org/jazz/" target="_blank">Vermont Youth Orchestra Jazz</a> in Colchester, VT. 
                        <br/><br/>
                        
                        <p className="customText" style={{ color:"#66AAFF", textAlign: "center" }}>
                            <b>STUDENT TESTIMONIALS:</b>
                        </p>
                            "Jake has been a wonderful saxophone teacher for our son. In addition to Jake's tremendous musical range, he is a very thoughtful and perceptive teacher, modifying each lesson as needed to encourage our son and bring his skills along. Jake has a gentle and encouraging approach that has worked so well. During the past 3 years, our son has developed greatly in both technical skill and musicality. Jake has introduced a good variety of music and it is a complete joy to hear Jake and our son playing together. It has also been both fun and inspiring for our son to see Jake performing in Burlington. I am thrilled we've found such a great teacher and would recommend him to any aspiring musician."<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;- Heather, parent of student
                        <br/><br/>
                            "Jake has been teaching my 11 year old for a few months now and he absolutely loves having lessons with him!   He has a great teaching style, mixing formal type instruction with more casual jam sessions.  He is extremely knowledgeable, kind,  and patient.   He will also take any song suggestions that my son might have and really run with it.  It makes the lessons really fun!"<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;- Carrie, parent of student
                        <br/><br/>
                            "I studied saxophone and clarinet with Jake for two and a half years. Jake is a master of his instrument and a truly beautiful player. He is also one of the most compassionate and gentle people I know. Jake is not only a great player and human being, but also an inspiring teacher.   Jake's years of professional experience and passion for the music really come through in the lessons. He gave me the confidence and tools I needed to be successful. Now, several years later, I have a degree from Berklee College of Music and a career as a professional saxophonist in Boston. I can truly say I would not be where I am today without Jake's mentorship. Jake has my highest recommendation. If you have the opportunity to work with him, do it!"<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;- <a href="https://www.sebamolnar.com" target="_blank">Seba Molnar</a>, former student
                        <br/><br/>
                            "Jake is incredibly knowledgeable about so many facets of music. First of all, he is a marvelous player, playing with verve and subtlety. He can speak to the structure of the tune and the strategies for soloing over that framework. He is good at assessing a player's abilities and giving them areas to work on. He really knows the saxophone, music theory and how to work with others. I would recommend him to anyone."<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;- Peter, adult student
                        <br/><br/>
                            "I started lessons with Jake about 6 months ago, after having taken a break from saxophone for over 15 years. He has been a great teacher and very patient as I've shaken off the rust. He does a great job of incorporating enough music theory so I know what I'm playing, but not so much that it becomes overwhelming. If you've been thinking about picking up saxophone, I definitely recommend Jake."<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;- Colin, adult student
                        <br/><br/>
                            "Jake is a phenomenal sax player and teacher. He's helped my middle school son play at a high level, and helped him also work on his improv skills. I highly recommend Jake to everyone who is looking for a sax teacher."<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;- Ryan, parent of student
                        <br/>
                        </p>

                    </div>
                    <div className="col-md-2"></div>
                </div>

                <Footer />
            </div>
        );
    }
}

const mapSizesToProps = ({ width }) => ({
    collapseButtonIsShowing: width < 1200,
    width: width
})

export default (withSizes(mapSizesToProps)(LessonsPage))
