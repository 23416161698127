import React, { Component } from "react";
// import { Link } from "react-router-dom";
import Helmet from 'react-helmet'
import Footer from '../layout/Footer';
// import NavigationBar from '../layout/NavigationBar'
import NavigationBarMobile from '../layout/NavigationBarMobile'
import NavigationBarDesktop from '../layout/NavigationBarDesktop'
import withSizes from 'react-sizes'

// import JakeBackgroundImage from '../../images/jake-higher-ground-backstage.jpg'
import JakeBackgroundImage from '../../images/JakeBalcony-small.jpg'

const HomeTextColor = "#b3d9ff";

class HomePage extends Component {
    render() {
        console.log('running HomePage.render; value of this.props.history: ' + this.props.history);

        const paragraphMargin = this.props.collapseButtonIsShowing ? "0px" : "20px"

        const isMobile = this.props.width <= 675
        
        const MobileHorizontalScreen = isMobile & (this.props.width > 500)
        
        var textBoxWidth
        if (this.props.width >= 992) {
            textBoxWidth = "60vw"
        } else if (this.props.width > 675 && this.props.width < 992) {
            textBoxWidth = "75vw"
        } else {
            textBoxWidth = "auto"
        }

        return (
            <div>

                {/* <header style={{width: "100%", height: "100%", top: "0", position: "fixed", minHeight: "400px", backgroundSize: "cover", backgroundImage: "url(" + Jake + ")" }}>

                </header> */}

                <Helmet bodyAttributes={{ style: "background-color: #FFFFFF" }}>
                    <title>{"Jake Whitesell – Home"}</title>
                    <body className={isMobile ? "homePageMobile" : "homePage"} />
                </Helmet>

                {window.innerWidth < 1050 ?
                    <NavigationBarMobile homePage/>
                    :
                    <NavigationBarDesktop homePage />
                }                

                <div style={{ backgroundColor: "#0F0F0F", display: "table-cell", verticalAlign: "middle", width: textBoxWidth, position: "fixed", bottom: "48px" }}>

                    {/* <div className="row" style={{ height: "32px" }}></div> */}

                    {(window.innerHeight >= 600) && (window.innerWidth >= 600) ?
                        <p className="customText" style={{ color: HomeTextColor, padding: "10px 10px 10px 10px", margin: "auto", textAlign: "justify", marginLeft: paragraphMargin, marginRight: paragraphMargin }}>
                            <b><font color="#66AAFF">JAKE WHITESELL</font> is a versatile saxophonist & woodwind doubler based in Burlington / Montpelier, VT. Jake is available for performances, touring, recording, musical theater, private lessons, and music score / transcribing work. Jake plays soprano, alto, tenor, and baritone saxophones, doubles on clarinets and flute, and has strong music reading and improvisational skills.</b>
                        </p>
                        :
                        <> </>

                    }

                </div>

                {(window.innerWidth < 600) || (window.innerHeight < 600) ?

                    <>
                        <div style={{ height: "50vh" }}></div>
                        <div style={{ backgroundColor: "black" }}>

                            <div className="row" style={{ backgroundColor: "black" }}>

                                <div className="col-md-12">

                                    {/* <div style={{ backgroundColor: "#000000" }}> */}
                                    {/* <p className="customText" style={{ padding: "10px 10px 10px 10px", margin: "auto", textAlign: "justify", marginLeft: paragraphMargin, marginRight: paragraphMargin }}> */}
                                    <p className="customText" style={{ color: HomeTextColor, padding: "10px 0px 10px 0px", margin: "auto", textAlign: "justify", marginLeft: paragraphMargin, marginRight: paragraphMargin }} >
                                        {/* <p className="customText"> */}
                                        <b><font color="#66AAFF">JAKE WHITESELL</font> is a versatile saxophonist & woodwind doubler based in Burlington / Montpelier, VT. Jake is available for performances, touring, recording, musical theater, private lessons, and music score / transcribing work. Jake plays soprano, alto, tenor, and baritone saxophones, doubles on flute and clarinet, and has strong music reading and improvisational skills.</b>
                                </p>
                                    {/* </div> */}
                                </div>
                            </div>

                            {/* <div className="d-flex flex-column" style={{ backgroundColor: "black", textAlign: "center", width: "100%" }}>

                                <div className="wrapper flex-grow-1"></div>
                                <footer>
                                    <div className="container-fluid text-center py-4 bg-black text-white-50">
                                        <small className="footerText">
                                            Copyright &copy; 2020 Jake Whitesell
                                        </small>
                                    </div>
                                </footer>

                            </div> */}
                        </div>
                    </>
                    :
                    <> </>

                }

                {/* <div> */}

                {/* <div style={{height: "50vh"}}></div> */}

                {/* <div className="row"> */}

                {/* <div className="col-md-2"></div> */}

                {/* <div className="col-md-8" style={{ backgroundColor: "rgb(193, 255, 255)" }}> */}
                {/* <div className="col-md-9"> */}

                {/* <div style={{ backgroundColor: "#0F0F0F", display: "table-cell", verticalAlign: "middle" }}> */}

                {/* <div className="row" style={{ height: "32px" }}></div> */}

                {/* <p className="customText" style={{ margin: "10px auto", textAlign: "justify", marginLeft: paragraphMargin, marginRight: paragraphMargin }}>
                                    <b>JAKE WHITESELL is a versatile saxophonist & woodwind doubler</b> based in central VT. He is available for performances, recording sessions, musicals, private lessons, and music score / transcribing work. Jake plays soprano, alto, tenor, and baritone saxophones, doubles on flute and clarinet, and has strong music reading and improvisational skills.
                                </p> */}

                {/* </div> */}


                {/* <div className="row" style={{ height: "32px" }}></div> */}

                {/* <p className="customText" style={{ textAlign: "justify", marginLeft: paragraphMargin, marginRight: paragraphMargin }}>
                                <b>Jake's jazz combo</b> is available to play at events, weddings, parties, concerts, restaurants, etc. The group can be tailored to meet the needs and the budget of the venue or occasion (duo, trio, quartet, etc.)
                            </p> */}

                {/* <div className="row" style={{ height: "32px" }}></div> */}

                {/* <p className="customText" style={{ textAlign: "justify", marginLeft: paragraphMargin, marginRight: paragraphMargin }}>
                                <b>Jake is fluent in many musical genres and currently performs regularly with:</b>
                                <br />
                                <a href="https://www.katwright.com">The Kat Wright Band</a>
                                <br />
                                The Jake Whitesell Trio/Quartet
                                <br />
                                <a href="http://www.jakewhitesell.com/WBQ.html">The Whitesell Brothers Quartet</a>
                                <br />
                                <a href="http://www.guaguavt.com/">Gua Gua</a>
                                <br />
                                <a href="http://www.thegrift.com/">The Grift</a>
                                <br />
                                <a href="https://joeyleone.com/">Joey Leone</a>
                                <br />
                                <a href="http://www.petitjazz.com/">George Petit</a>
                            </p> */}

                {/* <div className="row" style={{ height: "32px" }}></div> */}

                {/* <p className="customText" style={{ textAlign: "justify", marginLeft: paragraphMargin, marginRight: paragraphMargin }}>
                                <b>Jake offers private lessons</b> in saxophone and jazz improvisation / style / theory.
                            </p> */}

                {/* <div className="row" style={{ height: "32px" }}></div> */}

                {/* <p className="customText" style={{ textAlign: "justify", marginLeft: paragraphMargin, marginRight: paragraphMargin }}>
                                <b>Jake is also a music transcriptionist, arranger, and copyist / engraver</b>. Jake uses music notation software to produce easy-to-read, professional-quality music scores, transposed parts, lead sheets, etc. The final product is delivered in the versatile PDF document format, and/or as a Sibelius file.
                            </p> */}


                {/* </div> */}

                {/* // <div className="col-md-3"></div> */}

                {/* </div> */}

                {/* </div> */}



                {/* <Footer /> */}

                {/* <div className="d-flex flex-column" style={{ position: "fixed", bottom: "0px", textAlign: "center", width: "100%" }}>

                    <div className="wrapper flex-grow-1"></div>
                    <footer>
                        <div className="container-fluid text-center py-4 bg-black text-white-50">
                            <small className="footerText">

                                Copyright &copy; 2020 Jake Whitesell
                        </small>
                        </div>
                    </footer>

                </div> */}

            </div >
        );
    }
}

const mapSizesToProps = ({ width }) => ({
    collapseButtonIsShowing: width < 1200,
    width: width
})

export default (withSizes(mapSizesToProps)(HomePage))
