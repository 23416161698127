import React, { Component } from "react";
import Footer from '../layout/Footer';

import NavigationBarMobile from '../layout/NavigationBarMobile';
import NavigationBarDesktop from '../layout/NavigationBarDesktop';

import Helmet from 'react-helmet';
import withSizes from 'react-sizes'

import JakeHeadshot from '../../images/jake-headshot-vignette-small.jpg'

class AboutPage extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);

        this.state = {
            navbarIsOpen: false
        }
    }

    toggle() {
        this.setState({
            navbarIsOpen: !this.state.navbarIsOpen
        })
    }

    render() {

        const paragraphMargin = this.props.collapseButtonIsShowing ? "0px" : "20px"

        // HORIZONTAL LAYOUT

        return (

            <div>
                <Helmet>
                    <title>{"Jake Whitesell – About"}</title>
                    <body className={"normal"} />
                </Helmet>
                {window.innerWidth < 1050 ?
                    <NavigationBarMobile />
                    :
                    <NavigationBarDesktop />
                }

                <div style={{ height: "30px" }}></div>

                {/* {this.props.width > 575 ? <div style={{ height: "184px" }}></div> : <div style={{ height: "96px" }}></div>} */}

                <div className="row">
                    <div className="col-lg-2"></div>

                    <div className="col-lg-8" style={{ textAlign: "center", marginBottom: "16px" }}>

                        {this.props.width < 825 ?
                            <img className="img-fluid" style={{ height: "60vh", marginBottom: "20px" }} src={JakeHeadshot} />
                            :
                            <img className="img-fluid float-left" style={{ height: "40vw", marginRight: "24px", marginBottom: "16px" }} src={JakeHeadshot} />
                        }

                        <p className="customText" style={{ textAlign: "justify" }}>
                            One of Vermont's most versatile and in-demand saxophonists and jazz musicians, Jake Whitesell resides in the Burlington-Montpelier area and performs with The Jake Whitesell Trio / Quartet, <a href="https://www.facebook.com/TheSaturnPeoplesSoundCollective" target="_blank">The Saturn People's Sound Collective</a>, <a href="http://www.guaguavt.com" target="_blank">Guagua</a>, <a href="https://www.kerubomusic.com" target="_blank">Kerubo</a>, <a href="https://www.facebook.com/cfi.chicken.fat.injection" target="_blank">Chicken Fat Injection</a>, <a href="https://www.chadmusic.com/" target="_blank">Chad Hollister Band</a>, <a href="http://www.thegrift.com/" target="_blank">The Grift</a>, <a href="https://www.facebook.com/Soul-porpoise-101822805630098" target="_blank">Soul Porpoise</a>, Alma Picante, <a href="https://www.instagram.com/vermontjazzensemble/" target="_blank">The Vermont Jazz Ensemble</a>, and <a href="https://brianmccarthyjazz.com/the-jazz-orchestra" target="_blank">The Brian McCarthy Jazz Orchestra</a>. Jake is available for <a href="/lessons">private music lessons</a>, performances, weddings, recording sessions, musical theater productions, touring, and transcribing / arranging / music copying work. Jake plays soprano, alto, tenor, and baritone saxophones, and doubles on flute, clarinet and bass clarinet.
                        </p>

                        <p className="customText" style={{ textAlign: "justify" }}>
                            Jake's jazz projects, including <a href="./cannonball-quintet">Jake Whitesell's Cannonball Quintet</a> and <a href="./whitesell-brothers-quartet">The Whitesell Brothers Quartet</a> have been featured at the Burlington Discover Jazz Festival annually from 2013 to 2021, at the <a href="https://stowejazzfestival.org/" target="_blank">Stowe Jazz Festival</a> in 2019, 2022, and 2023, at the Newport Vermont Jazz Festival in 2015, and at the Flynn Space for First Night Burlington in 2017. They have also performed numerous times at Burlington, VT venues including <a href="https://www.deli126vt.com/" target="_blank">The 126</a>, <a href="http://radiobean.com/" target="_blank">Radio Bean</a>, <a href="http://www.foambrewers.com/" target="_blank">Foam Brewers</a>, <a href="http://www.redsquarevt.com/" target="_blank">Red Square</a>, <a href="http://hotelvt.com/dining-drinking" target="_blank">Juniper</a>, <a href="http://www.americanflatbread.com/" target="_blank">American Flatbread</a>, <a href="http://www.farmhousetg.com/home.html" target="_blank">The Farmhouse Tap &amp; Grille</a>, Bleu Seafood, <a href="http://www.leunigsbistro.com/" target="_blank">Leunig's</a>, and <a href="http://skinnypancake.com/" target="_blank">The Skinny Pancake</a>. 
                        </p>

                        <p className="customText" style={{ textAlign: "justify" }}>
                            As lead alto saxophonist with Wally Siebel's All-Star Big Band in Potsdam, NY, Jake has backed up jazz greats <a href="https://www.johnpizzarelli.com" target="_blank">John Pizzarelli</a>, <a href="http://garysmulyan.com/" target="_blank">Gary Smulyan</a>, <a href="http://kenpeplowski.com/" target="_blank">Ken Peplowski</a>, <a href="http://www.stevewilsonmusic.com/" target="_blank">Steve Wilson</a>, <a href="https://www.tommyigoe.com" target="_blank">Tommy Igoe</a>, and <a href="https://dennismackrelmusic.com" target="_blank">Dennis Mackrel</a>.
                        </p>

                        <p className="customText" style={{ textAlign: "justify" }}>
                            Jake toured extensively with <a href="https://www.katwright.com" target="_blank">Kat Wright</a> from 2009 to 2021 as saxophonist and horn arranger, performing at N.P.R. Mountain Stage and numerous festivals including Montreal Jazz Festival, Jam Cruise, Syracuse Jazz Festival, Rochester Lilac Festival, Arise Music Festival, Burlington Discover Jazz Festival, Grace Potter's Grand Point North, Frendly Gathering, Musik Fest, Rooster Walk, and Manifestivus, and opening for notable bands such as <a href="http://www.doobiebros.com/" target="_blank">The Doobie Brothers</a>, <a href="https://www.soulive.com/" target="_blank">Soulive</a>, <a href="http://www.gracepotter.com/" target="_blank">Grace Potter</a>, <a href="http://www.theoriginalwailers.com/" target="_blank">The Original Wailers</a>, <a href="http://www.lakestreetdive.com/" target="_blank">Lake Street Dive</a>, <a href="http://themotet.com/" target="_blank">The Motet</a>, <a href="https://www.thewoodbros.com" target="_blank">The Wood Brothers</a>, <a href="http://rubblebucket.com/" target="_blank">Rubblebucket</a>, and <a href="https://joeydosik.com">Joey Dosik</a>.
                        </p>

                        <p className="customText" style={{ textAlign: "justify" }}>
                            Jake has also performed with <a href="http://www.temptationsofficial.com/" target="_blank">The Temptations</a>, <a href="http://www.rayvegamusic.com/" target="_blank">Ray Vega</a>, <a href="https://www.facebook.com/malmaiz/" target="_blank">Mal Maiz</a>, <a href="http://www.barikamusic.com/" target="_blank">Barika</a>, <a href="https://www.facebook.com/Vorcza" target="_blank">Vorcza</a>, The Dartmouth Gospel Choir, Nick Cassarino, <a href="http://www.joeyleone.com/" target="_blank">Joey Leone's Chop Shop</a>, <a href="https://grippofunkband.com" target="_blank">Dave Grippo</a>, <a href="http://www.petitjazz.com/" target="_blank">George Petit</a>, <a href="http://www.paulasbell.com/" target="_blank">Paul Asbell</a>, <a href="http://www.peterapfelbaum.net/" target="_blank">Peter Apfelbaum</a>, <a href="http://www.eugeneuman.com/" target="_blank">Eugene Uman</a>, <a href="http://www.guydevito.com/" target="_blank">Guy DeVito</a>, <a href="http://craigeastman.com/" target="_blank">Craig Eastman</a>, <a href="http://www.eamesbrothersband.com/" target="_blank">The Eames Brothers Band</a>,<a href="https://relix.com/news/detail/matt_burrs_dark_side_of_the_mountain_returns_to_nectars_this_weekend/" target="_blank">Dark Side of the Mountain</a>, <a href="http://gabejarrett.com/" target="_blank">Gabe Jarrett</a>, <a href="https://vtjazz.org/education/jazz-in-the-community/vjc-big-band/" target="_blank">The Vermont Jazz Center Big Band</a>, <a href="https://www.facebook.com/pages/Vermont-Symphonic-Winds/353557708017482" target="_blank">Vermont Symphonic Winds</a>, and The Crane Wind Ensemble.
                        </p>

                        <p className="customText" style={{ textAlign: "justify" }}>
                            Jake has performed on woodwinds for musical theater productions including HAIR, Guys and Dolls, and The Threepenny Opera.
                        </p>

                        <p className="customText" style={{ textAlign: "justify" }}>
                            Jake is on the teaching faculty at <a href="https://www.monteverdimusic.org/" target="_blank">Monteverdi Music School</a> in Monteplier, VT and <a href="https://www.vyo.org/jazz/" target="_blank">Vermont Youth Orchestra Jazz</a> in Colchester, VT. 
                        </p>

                        <p className="customText" style={{ textAlign: "justify" }}>
                            Jake grew up in Potsdam, NY, where he began studying clarinet &amp; saxophone in 7th grade. Jake dug deeply into classic jazz recordings during highschool and formed a working jazz combo with his peers. Jake attended The Crane School of Music at S.U.N.Y. Potsdam, playing in numerous ensembles and studying saxophone with world-renowned classical saxophone artist / educator <a href="http://www.timothymcallister.com" target="_blank">Timothy McAllister</a>, and jazz with <a href="https://www.potsdam.edu/about/directory/faculty/zvacekbr">Bret Zvacek</a>, John Roney, and <a href="https://www.facebook.com/JazzPianoRager/" target="_blank">Josh Rager</a>.  Jake graduated in 2005 with a B.M. in Saxophone Performance and a Jazz Studies minor.  During school, Jake performed professionally with his jazz group and as lead alto saxophonist with The Crane Jazz Ensemble and Wally Siebel's All-Star Big Band. Since college, Jake has been performing and teaching in a variety of musical styles in NY, MA, and VT.
                        </p>

                        <div style={{ height: "32px" }}></div>

                    </div>
                    <div className="col-lg-2"></div>
                    
                </div>

                <div className="row">
                    <div className="col-lg-2"></div>

                    <div className="col-lg-8" style={{ textAlign: "center", marginBottom: "16px" }}>

                    <p className="customText" style={{ color:"#66AAFF", textAlign: "left" }}>
                            <b>DISCOGRAPHY:</b>
                        </p>

                        <p className="customText" style={{ textAlign: "left " }}>

                            Saturn People's Sound Collective - <a href="https://open.spotify.com/album/6PZyvp7lwYGXhIORhyPmmq">Saturn People's Sound Collective</a> (2022)
                            <br />
                            Kerubo - <a href="https://open.spotify.com/album/6R38qVOye4QgGpfFwmQBDO">Hali Ya Utu</a> (2021)
                            <br />
                            moe. - <a href="https://open.spotify.com/album/7LWNBgECfeyk358gftyHW4">
                                This Is Not, We Are</a> (2020)<br /> Treetop Mansion - <a href="https://open.spotify.com/album/6o26lS3JfKI92v0eGsp6yI">Up Till Morning</a> (2020)<br />
                            The Renegade Groove - <a href="https://open.spotify.com/album/1cajuZetaifPGTZNJXgPUU">The Renegade Groove</a> (2018)
                            <br />    
                            Paul Asbell - <a href="https://open.spotify.com/album/2D3I3WkDtGJCAJpKNoBz3R">Burmese Panther</a> (2018)
                            <br />
                            Robinson Morse's Sound of Mind - <a href="https://open.spotify.com/album/1HbYVHmc9A9p12wdOAqVok">Enough Is Plenty</a> (2017)
                            <br />
                            Barika - <a href="https://open.spotify.com/album/5pRPOUXUzPpptkevmnCkwb">When The Time Comes</a> (2017)
                            <br />
                            Kat Wright - <a href="https://open.spotify.com/album/1L4zpLL1CaxiR5hPPsjYns">Kat Wright on Audiotree Live</a> (2017)
                            <br />
                            Kat Wright - <a href="https://open.spotify.com/album/0XPrbbfmTsxSDy5dHEDF6e">By My Side</a> (2016)
                            <br />
                            Kat Wright - <a href="https://open.spotify.com/album/30sSmMeFPyjrMoSwtz6df4">Introducing ...</a> (2013)
                            <br />

                        </p>
                                                
                    </div>

                    <div className="col-lg-2"></div>

                </div>

                <div style={{ height: "24px" }}></div>


                <Footer />
            </div >
        );


        // VERTICAL LAYOUT

        // return (

        //     <div>
        //         <Helmet>
        //             <title>{"Jake Whitesell – Bio"}</title>
        //             <body className={"normal"} />
        //         </Helmet>
        //         {this.props.collapseButtonIsShowing ?
        //             <NavigationBar_New1 />
        //             :
        //             <NavigationBar_New2 />
        //         }

        //         <div style={{ height: "24px" }}></div>

                // <div className="row">
                //     <div className="col-md-2"></div>

                //     <div className="col-md-8" style={{textAlign: "center"}}>
                //         <img className="img-fluid" style={{height: "60vh"}} src={JakeHeadshot} />
                //     </div>

                //     <div className="col-md-2"></div>


                // </div>

        //         <div style={{ height: "24px" }}></div>




        //         <div className="row">
        //             <div className="col-md-2"></div>
        //             <div className="col-md-8" >                        

        //                 <p className="customText" style={{ textAlign: "justify", marginLeft: paragraphMargin, marginRight: paragraphMargin }}>
        //                     One of Vermont's most in-demand saxophonists (doubling on flute &amp; clarinet) and jazz musicians, Jake Whitesell resides in the Burlington-Montpelier area and performs with <a href="http://katwrightsoulband.com/" target="_blank">The Kat Wright Band</a> (touring nationally), The Jake Whitesell Trio/Quartet, <a href="http://www.jakewhitesell.com/WBQ.html" target="_blank">The Whitesell Brothers Quartet</a>, <a href="http://www.guaguavt.com" target="_blank">Gua Gua</a>, <a href="https://joeyleone.com/" target="_blank">Joey Leone</a>, and <a href="http://www.thegrift.com/" target="_blank">The Grift</a>. Jake is also a freelance performer, studio musician, private music teacher, and music transcriptionist / arranger / copyist / engraver.
        //                 </p>

        //                 <p className="customText" style={{ textAlign: "justify", marginLeft: paragraphMargin, marginRight: paragraphMargin }}>
        //                     Jake's jazz combos have been featured prominently at the annual Burlington Discover Jazz Festival since 2013, and have performed numerous times in Burlington at <a href="http://www.foambrewers.com/" target="_blank">Foam Brewers</a>, <a href="http://www.redsquarevt.com/" target="_blank">Red Square</a>, <a href="http://hotelvt.com/dining-drinking" target="_blank">Juniper</a>, <a href="http://www.americanflatbread.com/" target="_blank">American Flatbread</a>, <a href="http://www.farmhousetg.com/home.html" target="_blank">The Farmhouse Tap &amp; Grille</a>, Bleu Seafood, <a href="http://www.leunigsbistro.com/" target="_blank">Leunig's</a>, <a href="http://radiobean.com/" target="_blank">Radio Bean</a>, and <a href="http://skinnypancake.com/" target="_blank">The Skinny Pancake</a>. In 2011 and 2012, Jake taught theory and saxophone at the <a href="http://www.vtjazz.org/summer-programs/" target="_blank">Vermont Jazz Center Summer Camp</a>.
        //                 </p>

        //                 <p className="customText" style={{ textAlign: "justify", marginLeft: paragraphMargin, marginRight: paragraphMargin }}>
        //                     As saxophonist and horn arranger with <a href="http://katwrightsoulband.com/" target="_blank">The Kat Wright Band</a>, Jake has performed at numerous festivals including Jam Cruise, Montreal Jazz Festival, Syracuse Jazz Festival, Rochester Lilac Festival, Arise Music Festival, Burlington Discover Jazz Festival, Grace Potter's Grand Point North, Frendly Gathering, Musik Fest, Rooster Walk, and Manifestivus, and has opened for such bands as <a href="http://www.doobiebros.com/" target="_blank">The Doobie Brothers</a>, <a href="https://www.soulive.com/" target="_blank">Soulive</a>, <a href="http://www.gracepotter.com/" target="_blank">Grace Potter</a>, <a href="http://www.theoriginalwailers.com/" target="_blank">The Original Wailers</a>, <a href="http://www.lakestreetdive.com/" target="_blank">Lake Street Dive</a>, <a href="http://themotet.com/" target="_blank">The Motet</a> and <a href="http://rubblebucket.com/" target="_blank">Rubblebucket</a>.
        //                 </p>

        //                 <p className="customText" style={{ textAlign: "justify", marginLeft: paragraphMargin, marginRight: paragraphMargin }}>
        //                     Jake has also performed with <a href="http://www.temptationsofficial.com/" target="_blank">The Temptations</a>, <a href="http://www.johnpizzarelli.com/" target="_blank">John Pizzarelli</a>, <a href="http://www.rayvegamusic.com/" target="_blank">Ray Vega</a>, <a href="https://www.facebook.com/Vorcza" target="_blank">Vorcza</a>, The Dartmouth Gospel Choir, <a href="http://www.joeyleone.com/" target="_blank">Joey Leone's Chop Shop</a>, <a href="http://www.barikamusic.com/" target="_blank">Barika</a>, Nick Cassarino, <a href="http://dennismackrelmusic.com/" target="_blank">Dennis Mackrel</a>, <a href="http://www.peterapfelbaum.net/" target="_blank">Peter Apfelbaum</a>, <a href="http://www.eugeneuman.com/" target="_blank">Eugene Uman</a>, <a href="http://www.guydevito.com/" target="_blank">Guy DeVito</a>, <a href="http://craigeastman.com/" target="_blank">Craig Eastman</a>, <a href="https://www.facebook.com/malmaiz/" target="_blank">Mal Maiz</a>, <a href="http://www.eamesbrothersband.com/" target="_blank">The Eames Brothers Band</a>,  Dave Grippo, <a href="https://relix.com/news/detail/matt_burrs_dark_side_of_the_mountain_returns_to_nectars_this_weekend/" target="_blank">Dark Side of the Mountain</a>, <a href="http://gabejarrett.com/" target="_blank">Gabe Jarrett</a>, <a href="http://www.petitjazz.com/" target="_blank">George Petit</a>, <a href="http://www.paulasbell.com/" target="_blank">Paul Asbell</a>, <a href="https://www.facebook.com/bearquarium" target="_blank">Bearquarium</a>, The Vermont Jazz Center Big Band, <a href="https://www.facebook.com/pages/Vermont-Symphonic-Winds/353557708017482" target="_blank">Vermont Symphonic Winds</a>, and The Crane Wind Ensemble.
        //                 </p>

        //                 <p className="customText" style={{ textAlign: "justify", marginLeft: paragraphMargin, marginRight: paragraphMargin }}>
        //                     Jake grew up in Potsdam, NY, where he began studying clarinet &amp; saxophone in 7th grade. Jake dug deeply into classic jazz recordings during highschool and formed a working jazz combo with his peers. Jake attended The Crane School of Music at SUNY Potsdam, playing in numerous ensembles and studying saxophone with world-renowned classical saxophone artist / educator <a href="http://www.timothymcallister.com" target="_blank">Timothy McAllister</a>, and jazz with <a href="http://www.bretzvacek.com/" target="_blank">Bret Zvacek</a>, <a href="http://www.effendirecords.com/en/artist/john-roney" target="_blank">John Roney</a>, and <a href="http://www.joshrager.com/" target="_blank">Josh Rager</a>.  Jake graduated in 2005 with a B.M. in Saxophone Performance and a Jazz Studies minor.  During school, Jake performed professionally with his jazz group and as lead alto saxophonist with The Crane Jazz Ensemble and Wally Siebel's All-Star Big Band, backing up jazz greats such as <a href="http://garysmulyan.com/" target="_blank">Gary Smulyan</a>, <a href="http://kenpeplowski.com/" target="_blank">Ken Peplowski</a>, <a href="http://www.genebertoncini.com/" target="_blank">Gene Bertoncini</a>, and <a href="http://www.stevewilsonmusic.com/" target="_blank">Steve Wilson</a>.  Since college, Jake has been performing and teaching in a variety of musical styles in NY, MA, and VT.
        //                 </p>

        //             </div>
        //             <div className="col-md-2"></div>
        //         </div>

        //         <Footer />
        //     </div>
        // );
    }
}

const mapSizesToProps = ({ width }) => ({
    collapseButtonIsShowing: width < 1200,
    width: width
})

export default (withSizes(mapSizesToProps)(AboutPage))
