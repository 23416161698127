import React, { Component } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { Collapse, Navbar, NavbarToggler, NavbarBrand, NavbarText, Nav, NavItem, NavLink, /*Dropdown,*/ UncontrolledDropdown, DropdownItem, DropdownToggle, DropdownMenu, Spinner } from 'reactstrap';
import withSizes from 'react-sizes'
import { browserHistory } from 'react-router';
// import { withRouter } from 'react-router';
import uuid from "uuid";

import axios from 'axios';

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";

import SaxIcon from "../../images/sax-icon-yellow.png";

const NavTextColor = "#66AAFF";
const NavBarBackgroundColor = "black";
const SubmenuBackgroundColor = "black";

var MobileHorizontalScreen = false; 
var smallScreenHeight = false;
const isMobile = ((window.innerWidth < 650) || (window.innerHeight < 650)); 

const renderLogin = () => (
  // <Nav className="ml-auto" navbar>
  <Nav navbar>
    <NavItem className="px-2" style={{ width: '60px' }}>

      <NavLink href="/login">Log&nbsp;In</NavLink>
    </NavItem>
    <NavItem className="px-2" style={{ width: '60px' }}>

      <NavLink href="/register">Register</NavLink>
    </NavItem>
  </Nav>
)

class NavigationBar extends Component {

  constructor(props) {
    console.log('NavigationBar.constructor called.');

    super(props);
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.toggleError = this.toggleError.bind(this);

    // this.reloadRoute = this.reloadRoute.bind(this);

    this.state = {
      isOpen: false,
      dropdownError: false,
      dropdownPrice: false,
      dropdownVolume: false,

      groupsCollection: []
    };
  }

  componentDidMount() {
    console.log('NavigationBar.componentDidMount called.')

    console.log("\n\nDEBUG: value of this.props.router:")
    console.log(this.props.router)

    const url = global.serverAddress + '/api/groups'

    // axios.get('http://localhost:5000/api/groups')
    axios.get(url)
      .then(res => {
        this.setState({ groupsCollection: res.data });
        console.log('finished GET operation; value of this.state.groupsCollection:' + this.state.groupsCollection);
        this.forceUpdate();
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  // reloadRoute = (route) => {
  //   this.props.router.push({ pathname: '/empty' });
  //   this.props.router.replace({ pathname: '/' + route });
  // }


  renderGroupsDropdown() {

    // const { courses } = this.groupsCollection;
    console.log('running NavigationBar.renderGroupsDropdown.');

    const items = [];

    items.push(
      <DropdownItem className="navLinkTextCollapsableMenu" style={{ color: NavTextColor, backgroundColor: SubmenuBackgroundColor, textAlign: "left" }} /*key={index}*/ onClick={e =>
          this.props.history.push({ pathname: "/whitesell-brothers-quartet" })}>
          Whitesell Brothers Quartet
      </DropdownItem>
    )
    items.push(
      <DropdownItem className="navLinkText" style={{ color: NavTextColor, backgroundColor: SubmenuBackgroundColor }} /*key={index}*/ onClick={e =>
          this.props.history.push({ pathname: "/cannonball-quintet" })}>
          JW's Cannonball Quintet
      </DropdownItem>
    )

    items.push(
      <DropdownItem className="navLinkText" style={{ color: NavTextColor, backgroundColor: SubmenuBackgroundColor }} /*key={index}*/ onClick={e =>
          window.open("https://www.facebook.com/cfi.chicken.fat.injection", "_blank") }>
          Chicken Fat Injection
      </DropdownItem>
    )
    items.push(
      <DropdownItem className="navLinkText" style={{ color: NavTextColor, backgroundColor: SubmenuBackgroundColor }} /*key={index}*/ onClick={e =>
          window.open("http://www.guaguavt.com", "_blank") }>
          Guagua
      </DropdownItem>
    )
    items.push(
      <DropdownItem className="navLinkText" style={{ color: NavTextColor, backgroundColor: SubmenuBackgroundColor }} /*key={index}*/ onClick={e =>
          window.open("https://www.kerubomusic.com", "_blank") }>
          Kerubo
      </DropdownItem>
    )
    items.push(
      <DropdownItem className="navLinkText" style={{ color: NavTextColor, backgroundColor: SubmenuBackgroundColor }} /*key={index}*/ onClick={e =>
          window.open("https://www.facebook.com/TheSaturnPeoplesSoundCollective", "_blank") }>
          Saturn People's Sound Collective
      </DropdownItem>
    )
    items.push(
      <DropdownItem className="navLinkText" style={{ color: NavTextColor, backgroundColor: SubmenuBackgroundColor }} /*key={index}*/ onClick={e =>
          window.open("https://www.facebook.com/Soul-porpoise-101822805630098", "_blank") }>
          Soul Porpoise
      </DropdownItem>
    )

    console.log('entering renderGroupsDropdown for loop...');
    for (const [index, value] of this.state.groupsCollection.entries()) {
      console.log('index: ' + index + '; value: ' + value);
      const url = '/groups/' + value._id;
      console.log('url: ' + url + '; title: ' + value.name);

      items.push(
        <DropdownItem className={(smallScreenHeight) ? "navLinkTextCollapsableMenu py-0" : "navLinkTextCollapsableMenu py-1"} style={{ color: NavTextColor, backgroundColor: SubmenuBackgroundColor, textAlign: "left" }} key={index} onClick={e =>
          this.props.history.push({ pathname: url, search: '?query=abc', state: { group: value } })}>
          {value.name}
        </DropdownItem>
      )
      console.log('items: ' + items);
    }

    return (
       <UncontrolledDropdown direction="down" className={(smallScreenHeight) ? "navLinkTextCollapsableMenu nowrap py-0" : "navLinkTextCollapsableMenu nowrap py-1"} nav /* inNavbar */ >
        <DropdownToggle className="py-0" nav caret style={{ color: NavTextColor}}>BANDS</DropdownToggle>
        <DropdownMenu className="py-0" style={{ marginTop: (smallScreenHeight) ? "-1px" : "4px", marginBottom: (smallScreenHeight) ? "1px" : "-4px", marginLeft: "-170px", color: NavTextColor, backgroundColor: SubmenuBackgroundColor, textAlign: "left", borderStyle: "solid", borderWidth: "2px", borderRadius: "5px", borderColor: "#212529"}}>
          {items}
        </DropdownMenu>
      </UncontrolledDropdown>

    )

  }


  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
    // this.redirectToLandingPage();    
  };

  redirectToLandingPage() {
    return (
      <Redirect to="/" />
    )
  }

  renderGreeting(firstName, lastName) {
    const { user } = this.props.auth;

    return (
      // <Nav navbar>

      <UncontrolledDropdown className="navLinkText nowrap py-1" nav style={{ width: "120px" }}>
        <DropdownToggle style={{ color: 'white', textAlign: "center", display: "block", marginLeft: "8px", marginRight: "8px" }} nav caret>{firstName + " " + lastName}</DropdownToggle>
        <DropdownMenu className="bg-dark">
          <DropdownItem className="navLinkText bg-dark py-1" style={{ color: "white", backgroundColor: "black" }} href="/dashboard" onClick={this.clickHandler("/dashboard")}>My Account</DropdownItem>

          {user.roles.includes('admin') ? (<DropdownItem className="navLinkText bg-dark py-1" style={{ color: "white", backgroundColor: "black" }} href="/admin-panel" onClick={this.clickHandler("/admin-panel")}>Admin Panel</DropdownItem>) : (null)}

          <DropdownItem divider />
          <DropdownItem className="navLinkText bg-dark py-1" style={{ color: "white", backgroundColor: "black" }} href="/" onClick={this.onLogoutClick}>Log Out</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>

      // </Nav>
    );

  }

  toggleNavbar() {
    console.log('NavigationBar.toggleNavbar() called.');
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  toggleError() {
    this.setState({
      dropdownError: !this.state.dropdownError
    });
  }

  clickHandler(redirectTo) {
    console.log('NavigationBar.clickHandler(redirectTo) called; value of redirectTo:' + redirectTo);
  }


  render() {

    console.log('running NavigationBar.render.');

    const navbarClass = this.props.collapseButtonIsShowing ? "mx-auto navbar-right" : "mx-auto navbar-center"
    const navBarBorderStyle = this.props.collapseButtonIsShowing ? "none" : "solid"
    const navBarTextAlign = this.props.collapseButtonIsShowing ? "right" : "center"

    // const navbarHeaderTextClass = this.props.homePage ? "navbarHeaderText" : "navbarHeaderTextReduced"

    console.log("DEBUG: value of this.props.collapseButtonIsShowing: " + this.props.collapseButtonIsShowing)

    const { user } = this.props.auth;

    const collapseMenuOffset = /* = this.props.width < 650 ? 48 + ((this.props.width - 500) / 60) : 61 */ isMobile ? "50px" : "60px";

return (

      <section className="sidebar">

        {/* {MobileHorizontalScreen = (this.props.width > 500)} */}
     
        { smallScreenHeight = (window.innerHeight < 500) }

{/*     <Navbar dark expand="xl" className={MobileHorizontalScreen ? "py-1" : "fixed-top py-1"} style={{ marginLeft: MobileHorizontalScreen ? "-15px" : "0px", marginRight: MobileHorizontalScreen ? "-15px" : "0px", backgroundColor: NavBarBackgroundColor, height: "60px" }}> */}
        <Navbar dark expand="xl" className="fixed-top py-1" style={{backgroundColor: NavBarBackgroundColor, height: isMobile ? "50px" : "60px" /* borderStyle: "solid", borderBottomWidth: "2px", borderTopWidth: "0px", borderRightWidth: "0px", borderLeftWidth: "0px", borderColor: "#212529" */ }}>

          {/* <NavbarBrand href="/" className="py-3"> */}
          <NavbarBrand tag={Link} to="/" className={this.props.homePage ? "py-1" : "py-1"}>
            <div>
              {/* <h1 className="navbarHeaderText" style={{ color: "white", position: "absolute", top: "38px" }}>Jake Whitesell</h1> */}
              {/* <h1 className={navbarHeaderTextClass} style={{ color: "white" }}>JAKE WHITESELL</h1> */}
              {/* <span className="navbarHeaderTextReduced" style={{ color: NavTextColor, fontFamily: "Bowlby One SC" }}>Jake Whitesell</span> */}
              <span className="navbarHeaderTextReduced" style={{ color: NavTextColor }}>Jake Whitesell
              <img src={SaxIcon} height={28} style={{ marginLeft: "10px", marginTop: "5px" }} /></span> 
            </div>
          </NavbarBrand>

          {/* <NavbarToggler onClick={this.toggleNavbar}> */}
          <NavbarToggler onClick={this.toggleNavbar} style={{right: "12px", position: "fixed", /* borderColor: "#66AAFF" */ }}/>

          {/* {(MobileHorizontalScreen) ? "" : <div class='fadeout' ></div>} */}
          <div class="fadeout" style={{top: isMobile ? "50px" : "60px"}}></div>

        </Navbar>

        {/* <Collapse isOpen={this.state.isOpen} className={MobileHorizontalScreen ? "py-0" : "fixed-top py-0"} style={{ marginTop: (MobileHorizontalScreen ? (collapseMenuOffset - 85) : collapseMenuOffset), marginBottom: (MobileHorizontalScreen ? 20 : 0)}}> */}
        <Collapse isOpen={this.state.isOpen} className="fixed-top py-0" style={{ marginTop: collapseMenuOffset}}>

          <Nav className="ml-auto py-0" vertical navbar style={{ width: "104px", textAlign: "center", borderStyle: "solid", borderWidth: "2px", borderRadius: "7px", backgroundColor: "black" }}>

            {this.props.auth.isAuthenticated ? this.renderGreeting(user.firstName, user.lastName) : <> </>}

            <NavItem className="navLinkTextCollapsableMenu nowrap"> 
              <NavLink className={(smallScreenHeight) ? "nowrap py-0" : "nowrap py-1"} style={{ color: NavTextColor, marginTop:"5px" }} tag={Link} to="/">HOME</NavLink>
            </NavItem>

            <NavItem className="navLinkTextCollapsableMenu nowrap" >              
                <NavLink className={(smallScreenHeight) ? "nowrap py-0" : "nowrap py-1"} style={{ color: NavTextColor}} tag={Link} to="/about">ABOUT</NavLink>
            </NavItem>

            {this.renderGroupsDropdown()}
            
            <NavItem className="navLinkTextCollapsableMenu nowrap" >               
              <NavLink className={(smallScreenHeight) ? "nowrap py-0" : "nowrap py-1"} style={{ color: NavTextColor }} tag={Link} to="/shows">SHOWS</NavLink>
            </NavItem>

            <NavItem className="navLinkTextCollapsableMenu nowrap" >    
              <NavLink className={(smallScreenHeight) ? "nowrap py-0" : "nowrap py-1"} style={{ color: NavTextColor }} tag={Link} to="/listen">LISTEN</NavLink>
            </NavItem>

            <NavItem className="navLinkTextCollapsableMenu nowrap" >                  
              <NavLink className={(smallScreenHeight) ? "nowrap py-0" : "nowrap py-1"} style={{ color: NavTextColor }} tag={Link} to="/video">VIDEO</NavLink>
            </NavItem>

            <NavItem className="navLinkTextCollapsableMenu nowrap" >                  
              <NavLink className={(smallScreenHeight) ? "nowrap py-0" : "nowrap py-1"} style={{ color: NavTextColor }} tag={Link} to="/photos">PHOTOS</NavLink>
            </NavItem>

            <NavItem className="navLinkTextCollapsableMenu nowrap">                  
              <NavLink className={(smallScreenHeight) ? "nowrap py-0" : "nowrap py-1"} style={{ color: NavTextColor }} tag={Link} to="/lessons">LESSONS</NavLink>
            </NavItem>

            <NavItem className="navLinkTextCollapsableMenu nowrap">                  
              <NavLink className={(smallScreenHeight) ? "nowrap py-0" : "nowrap py-1"} style={{ color: NavTextColor, marginBottom: "3px" }} tag={Link} to="/contact">CONTACT</NavLink>
            </NavItem>
            
          </Nav>

        </Collapse>
                      
        {/* Add blank space after Navbar (depending on if Navbar is fixed or not) */}
        {/* {MobileHorizontalScreen ? "" : <div style={{ height: "80px"}}></div>} */}
        <div style={{ height: "60px"}}></div>

      </section>

    );

  }

}



NavigationBar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  progress: state.progress,
  auth: state.auth
});

const mapSizesToProps = ({ width }) => ({
  collapseButtonIsShowing: width < 1200,
  width: width
})


// old (working)
// export default connect(mapStateToProps, { logoutUser })(NavigationBar);

// new

export default connect(mapStateToProps, { logoutUser })(withRouter(withSizes(mapSizesToProps)(NavigationBar)));
